/*@font-face {
  font-family: 'Noto Color Emoji';
  src: local('Noto Color Emoji'), url(NotoColorEmoji.ttf) format('truetype');
}*/



.App {
  text-align: center;
  background: #4f444b;
  font-size: 10px;

  overflow: hidden;
  /*font-family: 'Noto Color Emoji';*/

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.grid{
  width: 900px;
  box-sizing: border-box;
}
.container{
  height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;


  flex-direction: column;
}

.fields {
  position: relative;
  top: 0;
  left: 0;
}

.line{
  width: 20px;
  margin-left: 2px;
  display: inline-block;
}

.square {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  border-radius: 2px;
  background: #e2dfc7;

}
.square.letterx2{
  background: #77caa9;
}
.square.letterx3{
  background: #499188;
}
.square.letterx4{
  background: #bfd682;
}
.square.wordx2{
  background: #f29cb6;
}
.square.wordx3{
  background: #eb6786;
}
.square.wordx4{
  background: #d87bbb;
}
.square.startsquare{
  background: #ffface;

}
.square.selected{
  position: absolute;
  z-index: 99;
  pointer-events: none;
  margin-top: -1px;
  margin-left: -1px;
  border-width: 1px;
  border-style: solid;
  border-color: #4f444b;
  border-radius: 20px;
  transform: scale(1.3);
  -transition: transform 0.3s ease;
}
.square.invis{
  background: #4f444b;
}
.lettercontainer{
  background: white;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  line-height: 20px;
  font-weight: bold;

}
.lettercontainertemp{
  background: #eaabab;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  line-height: 20px;
  font-weight: bold;

}
.lettercontainer.selected{
  position: absolute;
  z-index: 99;
  margin-top: -1px;
  margin-left: -1px;
  border-width: 1px;
  border-style: solid;
  border-color: #4f444b;
  border-radius: 20px;
  transform: scale(1.3);
  -transition: transform 0.3s ease;

}
.lettercontainertemp.selected{
  position: absolute;
  z-index: 99;
  margin-top: -1px;
  margin-left: -1px;
  border-width: 1px;
  border-style: solid;
  border-color: #4f444b;
  border-radius: 20px;
  transform: scale(1.3);
  -transition: transform 0.3s ease;

}
.letter{
  position: absolute;
  margin-top: 1px;
  width: 20px;
  height: 20px;
}
.letter.fade{
  color: #888888
}
.letterscore{
  z-index: 100;
  color: black;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 5px;
  line-height: 5px;
  margin-bottom: 2px;
  margin-right: 3px;
}
.multipliertext{
    margin-top: 1px;
    width: 20px;
    margin-top: 5px;
    color: black;
    opacity: 0.3;
    position: absolute;
    font-size: 5px;
    line-height: 5px;
    text-align: center;
}
.multipliernum{
    margin-top: 1px;
    width: 20px;
    bottom: 0;
    margin-bottom: 4px;
    color: black;
    opacity: 0.3;
    position: absolute;
    font-size: 9px;
    line-height: 5px;
    text-align: center;
    font-weight:bold;
}
.multipliertext.hide{
  visibility:hidden;
}
.multipliernum.hide{
  visibility:hidden;
}


.rightinfo{
  position: absolute;
  width: 200px;
  right: 0;
  top: 0;
  margin-right: -200px;
  color: white;
  font-size: 12px;
  text-align: left;
}
.lobbyinfo{
  position: absolute;
  top: 25%;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  color: white;
  font-size: 12px;
  text-align: center;
}
.leftinfo{
  position: absolute;
  width: 200px;
  left: 0;
  top: 0;
  margin-left: -200px;
  color: white;
  font-size: 12px;
  text-align: right;
}
.title{
  margin-top: 20px;
  /*width: 100px;*/
  font-weight: bold;
}
.wintext{
  margin-top: 20px;
  width: 100px;
  font-weight: bold;
}
.warning{
  font-size: 6px;
  width: 100px;
  margin-top: 20px;
}
.players{
  margin-top: 10px;
}
.poolcount{
  margin-top: 15px;
}
/*.player{
  height: 20px;
  width: 80px;
  padding-left: 5px;
  margin-top: 5px;
  border-radius: 20px;
  color: black;
  background: #e2dfd3;
  font-size: 10px;
  z-index: 2;
}*/
.player{
  height: 20px;
  width: 80px;
  margin-top: 5px;
  padding-bottom: 5px;
  color: white;
  font-size: 10px;
  z-index: 2;
}
.player.border{
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;
  border-color: white;
  border-style: solid;
}
.active{
  width: 1px;
  background: white;
  height: 12px;
  margin-top: 3px;
  margin-left: 80px;
  position: absolute;

}
/*.active{
  height: 25px;
  margin-top: -2px;
  margin-left: 72px;
  position: absolute;
}*/
.playername{
  position: absolute;
  line-height: 20px;
}
.player.lobby{
  width: auto;
  padding-bottom: 0px;
  margin-top: 0px;
  height: 15px;
}
/*.score{
  width : 29px;
  height: 18px;
  margin-top: 1px;
  margin-left: 50px;
  right :0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  display: inline-block;
  z-index: 1;
  background : #685e50;

  color: white;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
}*/
.score{
  width : 29px;
  height: 18px;
  margin-top: 0px;
  margin-left: 50px;
  right :0;

  display: inline-block;
  z-index: 1;

  color: white;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
}


.logo{
  position: absolute;
   top: 25%;
   left: 50%;
   width: 200px;
   height: 45px; /* Half the height */
   margin-left: -100px;
   margin-top: -33px;
   z-index: 1;
}
.smalllogo{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 40px;
  margin-bottom: 35px;
  width: 120px;
  height: 27px;
}

.progress{
  width: 200px;
  border-top: 0px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 0px;
  border-color: white;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;


  flex-direction: column;
}
.progressbar{
  background: white;
  height: 1px;
}
.progresstext{
  color: white;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.boardpreview{
  position: absolute;
  top: 60%;
  color: white;
  font-size: 10px;
}
.boardscale{
  transform: scale(0.2);
  margin-top: -130px;
}
.boardtitle{
  font-size: 8px;
  font-weight: lighter;
}

.emoji{
  height: 12px;
  margin-top: 3px;
  display: inline-block;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
